export default class Background {
    init() {
        this.createOuterFrame();
        this.createCircles();
    }

    createCircles() {
        //CIRCLES
        //------------------------------------------
        //We get the number of circles we need to create
        let numberOfCircles = 4;

        let container = document.createElement("div");
        container.classList.add("bg");

        //We loop to create <div> elements with the class "bg-circle"
        for (let i = 1; i <= numberOfCircles; i++) {
            //We create a new <div> element
            let circle = document.createElement("div");
            //We add the class "bg-circle"
            circle.classList.add("circle");
            circle.classList.add("circle" + i);
            //We add the circle to the body
            container.appendChild(circle);
        }
        document.body.prepend(container);
    }

    createOuterFrame() {
        //OUTER FRAME
        //-------------------------------------------
        let outerFrame = document.createElement("div");
        outerFrame.classList.add("outer");

        for (let i = 1; i <= 4; i++) {
            let part = document.createElement("div");
            part.classList.add("part");
            part.classList.add("part" + i);
            outerFrame.appendChild(part);
        }

        document.getElementById("wrapper").prepend(outerFrame);
    }
}
